.modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(69,84,122, 0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-height: 737px){    
    .modal{
        border-radius: 15px;
    }
}
