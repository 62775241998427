.web-body .aboutus-content {
  background-color: #F7F7FA;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
}

.web-body .aboutus-content > h1 {
  margin: 0;
  font-size: 28px;
  color: #506290;
  margin-bottom: 25px;
  text-align: center;
}

.web-body .aboutus-content > p {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #707070;
  margin-bottom: 25px;
  max-width: 575px;
}

.web-body .aboutus-img {
  height: 575px;
  width: 100%;
  background-image: url('../../../assets/images/aboutBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

@media(max-width: 700px){

  .web-body .aboutus-img {
    height: 375px;  
  }
}
