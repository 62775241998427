/**
 * @file: src/components/rating/rating.style.js
 * @description Rating Component Style
 * @copyright: Verge Technologies 2018
 * @author Fabio Loreggian fabio@verge.co.za
 */

.rating-container{
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.star {
  width: 50px;
  height:  50px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
}
