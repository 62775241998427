.web-home {
  position: absolute;
  top: 0;
  width: 100%;
}

.web-home .header-image-container {
  height: 792px;
  width: 100%;
}

.web-home .header-image {
  background-image: url('../../../assets/images/homeBg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 792px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 90px;
}

.web-home .header-image .opacity-cover {
  opacity: 0.6;
  background-color: #fff;
  width: 150%;
  height: 792px;
  position: absolute;
  z-index: 2;
  left:-10px
}

.web-home .header-image .header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
}

.web-home .header-image .header-text-container > h1 {
  font-size: 45px;
  color: #45547A;
  max-width: 500px;
  text-align: left;
}

.web-home .header-image .header-text-container > p {
  font-size: 20px;
  color: #45547A;
  text-align: left;
}

.web-home .header-image .header-text-container .stores {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;
}

.web-home .header-image > div > #heading-actions > button {
  font-size: 17px;
  font-weight: 600;
}

.web-home .header-image > div .stores > a > img {
  cursor: pointer;
}
.web-home .footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-home .footer-contact > p {
  color: #45547A;
  font-size: 18px;
  margin: 5px;
}

.web-home .footer-contact > .footer-input {
  position: relative;
  margin-top: 15px;
}

.web-home .footer-contact > .footer-input > input {
  padding: 15px;
  border: none;
  border-radius: 8px;
  width: 350px;
  font-size: 20px;
  outline: none;
  height: 60px;

}

.web-home .footer-contact > .footer-input > input::placeholder {
  color: #CCD1DB;
}

.web-home .footer-contact > .footer-input > button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #506290;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  cursor: pointer;
}



.web-home .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.web-home .carousel-container {
  height: 900px;
}

.web-home .grey-container {
  background-color: #F7F7FA;
  height: 100%;
  width: 100%;
  padding-top: 25px;
}

.web-home .content-container > h1{
  color: #506290;
  font-size: 28px;
  text-align: center;
}

.web-home .content-container > p{
  color: #707070;
  font-size: 16px;
  line-height: 24px;
  max-width: 550px;
  text-align: center;
}

.web-home .features-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-home .features-container > .feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-home .features-container > .feature-content > .feature {
  display: flex;
  outline: none;
  border: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  max-width: 500px;
  margin: 12px;

}

.web-home .features-container > .feature-content > .feature > .feature-text {
  text-align: left;
  max-width: 375px;
  margin-bottom: 15px;
}

.web-home .features-container > .feature-content > .feature > .feature-text > h3 {
  font-size: 17px;
  color: #9B9FA7;
}

.web-home .features-container > .feature-content > .feature > .feature-text > p {
  font-size: 16px;
  color: #9B9FA7;
  line-height: 23px;
}

.web-home .features-container > .feature-content > .feature >  svg {
  width: 60px;
  margin-right: 10px;
  height: 100%;
  display: block;
}

.web-home .features-container > .feature-content > .feature >  svg * {
  stroke: #9B9FA7;
}


.web-home .features-container > .feature-content > .feature.selected,
.web-home .features-container > .feature-content > .feature:hover {
  box-shadow: 0px 7px 20px rgba(0,0,0,0.16);
}

.web-home .features-container > .feature-content > .feature.selected > .feature-text > h3,
.web-home .features-container > .feature-content > .feature:hover > .feature-text > h3 {
  color: #506290;
}

.web-home .features-container > .feature-content > .feature.selected > .feature-text > p,
.web-home .features-container > .feature-content > .feature:hover > .feature-text > p {
  color: #707070;
}

.web-home .features-container > .feature-content > .feature.selected > svg *,
.web-home .features-container > .feature-content > .feature:hover > svg * {
  stroke: #506290;
}

.web-home .features-container > .feature-img {
  height: 775px;
  width: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.web-home .clients-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #F7F7FA;
}

.web-home .clients-container > .client-img,
.web-home .clients-container > .client-carousel {
  width: 50%;
  height: 350px;
  left: -100px;
}

.web-home .clients-container > .client-img {
  background-position: center;
  background-size: cover;
}

.web-home .clients-container > .client-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
}

.web-home .clients-container > .client-carousel > p {
  color: #707070;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.web-home .clients-container > .client-carousel > h1 {
  color: #506290;
  font-size: 22px;
  text-align: center;
}

.web-home .clients-container > .client-carousel > .indicators {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.web-home .clients-container > .client-carousel > .indicators > .indicator {
  width: 6px;
  height: 6px; 
  border-radius: 50%;
  background-color: #78849E;
  opacity: 0.39;
  margin: 0px 5px;
}

.web-home .clients-container > .client-carousel > .indicators > .indicator.selected {
  width: 8px;
  height: 8px; 
  border-radius: 50%;
  background-color:#20D89B;
  opacity: 1;
}


.web-home .vehicles-container {
  width: calc(100% - 100px);
  max-width: 1366px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.web-home .vehicles-container  .vehicles-content-container {
  width: 100%;
  height: 375px;
  display: none;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 7px 20px rgba(0,0,0,0.16);
}

.web-home .vehicles-container  .vehicles-content-container.large.selected {
  display: flex;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-content {
  width: 50%;
  height: 100%;
  background-color: #fff;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-content > h2 {
  font-size: 17px;
  color: #707070;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-content > h1 {
  font-size: 28px;
  color: #506290;
  margin: 0;
  margin-bottom: 15px;
  text-align: left;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-content > p {
  font-size: 16px;
  color: #707070;
  margin: 0;
  margin-bottom: 25px;
  text-align: left;
  white-space: pre-wrap;
  max-width: 390px;
  line-height: 24px;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-content > a {
  font-size: 16px;
  color: #506290;
  margin: 0;
  text-decoration: none;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image::before {
  content: "";
  position: absolute;
  background-color: rgba(0,0,0,0.25);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image::after {
  content: "";
  background-color: #fff;
  width: 60px;
  height: 3px;
  border-radius: 2px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .image-row-50,
.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .smaller-images {
  width: 50%;
  height: 100%;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .smaller-images {
  display: flex;
  flex-direction: column;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .smaller-images .image-col-50 {
  height: 50%;
  width: 100%;
}

.web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image > p {
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 1;
}


/* Vehicles Icons */
.web-home .vehicles-container > .icons-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0px 7px 20px rgba(0,0,0,0.16);
  width: 100%;
  max-width: 800px;
}

.web-home .vehicles-container > .icons-container > .icon-container {
  width: 160px;
  height: 117px;
}

.web-home .vehicles-container > .icons-container > .icon-container > button {
  outline: none;
  border: none;
  background-color: #fff;
  width: 100%;
  height: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #EAEAEA;
  cursor: pointer;
}

.web-home .vehicles-container > .icons-container > .icon-container > button > svg {
  display: block;
  height: 65px;
  width: 55px;
  margin-bottom: 5px;
}

.web-home .vehicles-container > .icons-container > .icon-container > button > p {
  font-size: 15px;
  color: #20D89B;
}

.web-home .vehicles-container > .icons-container > .icon-container > button:hover,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected {
  background-color: #20D89B;
}

.web-home .vehicles-container > .icons-container > .icon-container > button:hover > p,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected > p {
  color: #fff;
}

/* Scooter Selected */
.web-home .vehicles-container > .icons-container > .icon-container > button.SCOOTER:hover > svg > g > g > g path:last-child,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.SCOOTER > svg > g > g > g path:last-child {
  fill: #fff;
}

.web-home .vehicles-container > .icons-container > .icon-container > button.SCOOTER:hover > svg > g > g > path,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.SCOOTER > svg > g > g > path {
  stroke: #fff;
  fill: #20d89b;
}

/* Light Vehicle Selected */
.web-home .vehicles-container > .icons-container > .icon-container > button.LIGHTVEHICLE:hover > svg path,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.LIGHTVEHICLE > svg path {
  stroke: #fff;
}

/* Bakkie Selected */
.web-home .vehicles-container > .icons-container > .icon-container > button.BAKKIE:hover > svg > g > g path:last-child,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.BAKKIE > svg > g > g path:last-child {
  fill: #fff;
}

.web-home .vehicles-container > .icons-container > .icon-container > button.BAKKIE:hover > svg > g > path,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.BAKKIE > svg > g > path {
  stroke: #fff;
}

/* Van Selected */
.web-home .vehicles-container > .icons-container > .icon-container > button.VAN:hover > svg > g > g path:last-child,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.VAN > svg > g > g path:last-child {
  fill: #fff;
}

.web-home .vehicles-container > .icons-container > .icon-container > button.VAN:hover > svg > g > path,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.VAN > svg > g > path {
  stroke: #fff;
}

/* Truck Selected */
.web-home .vehicles-container > .icons-container > .icon-container > button.TRUCK:hover > svg > g > g > g path:last-child,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.TRUCK > svg > g > g > g path:last-child {
  fill: #fff;
}

.web-home .vehicles-container > .icons-container > .icon-container > button.TRUCK:hover > svg > g > g > path,
.web-home .vehicles-container > .icons-container > .icon-container > button.selected.TRUCK > svg > g > g >path {
  stroke: #fff;
}


@media(max-width: 700px){
  .web-home .header-image-container {
    height: 400px;
  }
  
  .web-home .header-image {
    height: 400px;
  }

  .web-home .features-container > .feature-img {
    display: none;
  }

  .web-home .clients-container {
    flex-direction: column;
  }

  .web-home .clients-container > .client-carousel {
    padding: 25px;
  }

  .web-home .clients-container > .client-img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 25px 0px;
  }

  .web-home .carousel-container {
    height: 1100px;
  }

  .web-home .clients-container > .client-carousel {
    width: 100%;
    height: auto;
  }

  .web-home .clients-container > .client-carousel > p {
    font-size: 16px;
  }

  #heading-actions {
    display: none;
  }

  .web-home .vehicles-container .vehicles-content-container.large,
  .web-home .vehicles-container  .vehicles-content-container.large.selected {
    display: none;
  }

  .web-home .vehicles-container  .vehicles-content-container.small.selected {
    display: flex;
  }

  .web-home .vehicles-container .vehicles-content-container {
    display: none;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-content > h2, 
  .web-home .vehicles-container  .vehicles-content-container > .vehicle-content > a{
    display: none;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images {
    flex-direction: column;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images .image-row-50,
  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images .smaller-images {
    width: 100%;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images .image-row-50{
    height: 50%;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image {
    padding: 15px;
  }
  .web-home .vehicles-container  .vehicles-content-container > .vehicle-content {
    padding: 25px 15px;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-content > h1 {
    font-size: 20px;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-content > p {
    font-size: 14px;
  }

  .web-home .vehicles-container  .vehicles-content-container > .vehicle-images .v-image > p {
    font-size: 16px;
  }

  /* Vehicle Icons */

  .web-home .vehicles-container {
    margin: 50px 0px;
    width: 100%;
  }

  .web-home .vehicles-container > .icons-container {
    flex-direction: column;
  }

  .web-home .vehicles-container > .icons-container > .icon-container {
    width: 100%;
    height: auto;
  }

  .web-home .vehicles-container > .icons-container > .icon-container > button {
    height: 45px;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    border: none;
    border-top: solid 1px #EAEAEA;
  }

  .web-home .vehicles-container > .icons-container > .icon-container > button > svg {
    display: block;
    height: 30px;
    margin: 0px 10px;
  }
  .web-home .footer-img {
    height: 376px;
  }  

  .web-home .footer-contact {
    display: none;
  }
}