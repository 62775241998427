.banner_container {
    position: fixed;
    top: 0;
    height: 15px;
    width: 100%;
    height: fit-content;
    background: #45547A;
    z-index: 100000;
    display: flex;
    justify-content: center;
    opacity: 0.9;
}

.banner_text {
    text-align: center;
    font-style: italic;
    font-size: medium;
    color: #fff;
    padding: 2px;
}

.banner_text a {
    color: #fff
}

@media only screen and (max-width: 600px) {
    .banner_text {
        font-size: small;
    }
}