.signup{
    width: 100%;
    position: relative;
    background-color: #F7F7FA
}

.signup .body{
    width: 100%;
    min-height: calc(100vh - 221px);
    position: relative;    
    flex-direction: column;
    overflow-x: hidden;
}

.custom-div-w100 > div {
    width: 100% !important;
}

.signup .body .background-content {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.3);
  }
  
.signup .body .background-content > p.text-bold {
    font-size: 40px;
    color: #45547A;
  }

.signup .body .card {
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    border-radius: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: calc(100% - 95px);
    min-width: 708px;
    max-width: 1024px;
}

.signup .body .card.center {
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
}

.signup .body .card.half-card {
    flex-direction: row;
    align-items: flex-start
}

.signup .body .card .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.signup .card .card-content .card-input{
    width: 100%;
    margin-bottom: 15px; 
    display: flex;
    justify-content: space-between
}

.signup .card .card-content > .heading{
    font-size: 32px;
    color: #506290;
    margin-bottom: 30px;
}

.signup .card .card-content > .text-content{
    font-size: 18px;
    color: #707070;
    margin-bottom: 5px;
}

.signup .body .otp-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px; 
}

.signup .body .otp-container .otp-container-cont {
    display: flex; 
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    min-height: 200px;

}
.signup .body .otp-container .otp-container-cont .otp-text {
    color: #959DAD;
    opacity: 0.51;
}

.signup .body .otp-container > div > div > Button {
    width: 100%;
    max-width: 327px;
    min-width: 148px;
    margin-top: 30px;
}

.signup .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.signup .button-container.full {
    max-width: 515px;
}

.signup .vehicle-type-container {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 525px;
    flex-wrap: wrap;
}

.signup .vehicle-type-container .vehicle-type {
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #CCD1DB;
    width: 155px;
    height: 165px;
    outline: none;
    color: #CCD1DB;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px;
    font-size: 20px;
    cursor: pointer;
    flex-direction: column;
}

.signup .vehicle-type-container .vehicle-type.selected {
    border-color: #20D89B;
    color: #20D89B;
}

.signup .vehicle-type-container .vehicle-type .icon-container {
    width: 100px;
}

.signup .vehicle-type-container .vehicle-type .icon-container > svg * {
    fill: #CCD1DB;
}

.signup .vehicle-type-container .vehicle-type.selected .icon-container > svg * {
    fill: #20D89B;
}

.signup .vehicle-type-container .vehicle-type:hover .icon-container > svg * {
    fill: #506290;
}



.signup .vehicle-type-container .vehicle-type:hover{
    border-color: #506290;
    color: #506290;
}

.signup .legal {
    border: 1px solid #506290;
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px 0px;
    padding: 24px;
}

.signup .legal .legal-heading {
    font-size: 24px;
    color: #454F63;
    margin: 15px 0px;
}

.signup .legal .legal-sub-heading {
    font-size: 16px;
    color: #454F63;
    margin: 15px 0px;
}

.signup .legal .legal-text {
    font-size: 14px;
    color: #78849E;
    margin: 5px 0px;
}

.signup .legal .legal-list {
    
}

.signup .legal .legal-list .list-item {
    font-size: 14px;
    color: #78849E;
    margin-bottom: 15px;
}
.signup .legal .legal-list .list-item > b {
    color: #454F63;
    font-weight: 600;
}
.signup .map-container {
    width: 926px;
    height: 605px;
    position: relative;
    margin-bottom: 15px;
}

.signup .map-container .map-controls {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 345px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    border-radius: 10px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.signup .map-container .map-controls > input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    color: #454F63;
}

.signup .map-container .map-controls.suggestions {
    top: 80px;
    height: auto;
}

.signup .map-container .map-controls.suggestions .suggestion {
    background-color: transparent;
    width: 100%;
    min-height: 50px;
    height: 50px;
    margin: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #454F63;
    text-align: left;
    cursor: pointer;
    display: block;
}

.signup .map-container .map-controls.suggestions .suggestion:hover {    
    color: #78849E;
}

.signup .svg-container {
    margin-top: 35px;
    width: 172px;
    height: 172px;
}

/* Media Queries */
@media(max-width: 700px) {
    .signup .body .card {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
    }

    .signup .body .card.half-card {
        flex-direction: column;
    }

    .signup .card .card-content {
        margin: 20px 0px;
    }

    .signup .card .card-content > .heading,
    .signup .card .card-content > .text-content{
        text-align: center;
    }

    .signup .vehicle-type-container {
        align-items: center;
        justify-content: center;
    }

    .signup .body .background-content{
        justify-content: center;
        align-items: center;
        padding: 75px;
        text-align: center;
    }

    .signup .map-container{
        width: calc(100% + 100px);
    }
}



