.web-body {
  position: absolute;
  top: 0;
  width: 100%;
}

.web-body .header-image-container {
  height: 523px;
  width: 100%;
}

.web-body .header-image-container .ellipse-container {
  background-color: #F7F7FA;
}

.web-body .header-image {
  background-image: url('../../../assets/images/boxelHeadingBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 523px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-body .header-image > h1 {
  font-size: 48px;
  color: #506290;
  text-shadow:  0px 2px 4px rgba(0,0,0,0.16);
  text-align: center;
  background-color: rgba(255,255,255,0.3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.web-body .content-background {
  height: 625px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.web-body .content-background .phone-background {
  background-color: #F7F7FA;
  height: 625px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-body .content-container {
  position: relative;
  margin-top: -500px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-body .content-container .phone-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-body .content-container .phone-container > img {

}

.web-body .content-container .phone-container > div {
  padding: 15px 50px;
  max-width: 500px;
}

.web-body .content-container .phone-container > div > p.phone-step {
  color: #506290;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 15px;
}

.web-body .content-container .phone-container > div > p.phone-step-heading {
  color: #506290;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.web-body .content-container .phone-container > div > p.phone-step-text {
  color: #707070;
  font-size: 16px;
  line-height: 24px;
}

.web-body .contact-container {
  position: relative;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-body .contact-content {
  background-color: #F7F7FA;
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
}

.web-body .contact-content > .contact-input-container {
  margin-top: 50px;
  width: 100%;
  max-width: 510px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.web-body .contact-content > .contact-input-container > div:nth-child(3),
.web-body .contact-content > .contact-input-container > div:nth-child(5){
  width: 100%;
}

.web-body .contact-content > p,
.web-body .contact-content > p > a {
  color: #506290;
  font-size: 17px;
  text-align: center;
}

.web-body .contact-content .input-50 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

@media(max-width: 700px){
  .web-body .content-container .phone-container {
    flex-direction: column;
  }

  .web-body .header-image-container {
    height: 350px;
  }
  
  .web-body .header-image {
    height: 350px;
  }
}


