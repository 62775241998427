.button {
  width: 148px;
  height: 45px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.16);
  position: relative;  
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.button.primary {
  background-color: #fff;
  border-color: #506290;
  color: #506290;
}

.button.secondary {
  background-color: #20D89B;
  border-color: #20D89B;
  color: #fff;
}

.button.primary:hover {
  background-color: #506290;
  color: #fff;
}

.button.secondary:hover {
  background-color: #fff;
  color: #20D89B;
}

.button.primary.disabled {
  border-color: grey;
  color: gray;
  cursor: not-allowed;
}

.button.secondary.disabled {
  background-color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.button.primary.disabled:hover {
  border-color: grey;
  background-color: #fff;
  color: gray;
}

.button.secondary.disabled:hover {
  background-color: grey;
  border-color: grey;
  color: #fff;
  cursor: not-allowed;
}

.button.primary.loading,
.button.secondary.loading{
  cursor: wait;
}

.button.primary.loading:hover {
  background-color: #fff;
}

.button.secondary.loading:hover {
  background-color: #20D89B;
}


