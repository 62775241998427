.header.clear {
  background-image: linear-gradient(rgba(0,0,0,0.59), rgba(0,0,0,0.20), rgba(0,0,0,0.0)) !important;
  background-color: transparent;
  box-shadow: none !important;
}

.header.clear .header-links > a.text{
  color: #fff !important;
  padding: 10px;
}

.header.clear .header-links > a.text:last-child{
  background-color: rgba(255,255,255,0.39);
  border-radius: 5px;
}

.header.clear .header-logo > svg > path{
  fill: #fff;
}

.header.clear .header-logo > svg > g > path{
  fill: #fff;
}

.header.clear .header-logo > svg > g > path:nth-child(1),
.header.clear .header-logo > svg > g > path:nth-child(2){
  fill: #20d89b;
}

@media(max-width: 700px){
  .header.clear {
    background-color: #fff !important;
    background-image: none !important;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.16) !important;
  }

  .header.clear .header-links > a.text,
  .header-dropdown.clear > a.text {
      color: #45547A !important;
  }

  .header.clear .header-logo > svg > path{
    fill: #45547a;
  }
  .header.clear .header-logo > svg > g > path{
    fill: #45547a;
  }
  .header.clear .header-logo > svg > g > path:nth-child(1),
  .header.clear .header-logo > svg > g > path:nth-child(2){
    fill: #20d89b;
  }
}