.web-business {
  position: absolute;
  top: 0;
  width: 100%;
}

.web-business .header-image-container {
  height: 792px;
  width: 100%;
}

.web-business .header-image {
  background-image: url('../../../assets/images/businessBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 792px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 50px;
}

.web-business .header-image > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.web-business .header-image > div > h1 {
  font-size: 45px;
  color: #45547A;
  max-width: 500px;
  text-align: left;
}

.web-business .header-image > div > p {
  font-size: 20px;
  color: #45547A;
  text-align: left;
}

.web-business .header-image > div > #heading-actions > button {
  font-size: 17px;
  font-weight: 600;
}

.web-business .header-image > div .stores {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;
}

.web-business .header-image > div .stores > a > img {
  cursor: pointer;
}

.web-business .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.grey-container {
  background-color: #F7F7FA;
  height: 100%;
  width: 100%;
}

.web-business .content-container > h1{
  color: #506290;
  font-size: 28px;
  text-align: center;
}

.web-business .content-container > p{
  color: #707070;
  font-size: 16px;
  line-height: 24px;
  max-width: 550px;
  text-align: center;
}

.web-business .business-ellipse {
  height: 950px;
}

.web-business .business-ellipse-content {
  height: 950px;
  background-color: #F7F7FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.web-business .business-ellipse-content > h1 {
  font-size: 28px;
  color: #506290;
  text-align: center;
  margin: 100px 0px;
}

.web-business .business-ellipse-content > div.help-images {
  height: 470px;
  width: 100%;
  display: flex;
}

.web-business .business-ellipse-content > div.help-images > div.help-image {
  width: 20%;
  height: 100%;
  background-position: center;
  background-size: cover;  
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 25px;
  position: relative;
}

.web-business .business-ellipse-content > div.help-images > div.help-image::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.33);
  z-index: 0;
  content: "";
}

.web-business .business-ellipse-content > div.help-images > div.help-image > p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 5px;
  z-index: 1;
  position: relative;
}

.web-business .business-ellipse-content > div.help-images > div.help-image > .hr {
  width: 60px;
  background-color: #fff;
  height: 5px;
  border-radius: 2px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}

.web-business .business-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
}

.web-business .business-info-container > .business-card {
  padding: 25px;
  margin: 20px;
  max-width: 350px;
  min-height: 285px;
}

.web-business .business-info-container > .business-card > p {
  color: #707070;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}
.web-business .business-info-container > .business-card > h1 {
  color: #506290;
  font-size: 16px;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
}

@media(max-width: 700px){

  .web-business .header-image-container {
    height: 400px;
  }
  
  .web-business .header-image {
    height: 400px;
  }

  .web-business .business-ellipse {
    height: 1000px;
  }
  
  .web-business .business-ellipse-content {
    height: 1000px;
  }

  .web-business .business-ellipse-content > div.help-images {
    height: 800px;
    width: 100%;
    flex-direction: column;
  }

  .web-business .business-ellipse-content > h1 {
    margin: 25px 0px;
  }

  .web-business .business-info-container {
    display: flex;
    flex-direction: column;
  }

  .web-business .business-info-container > .business-card {
    box-shadow: 0px 7px 20px rgba(0,0,0,0.16);
    min-height: auto;
  }

  .web-business .business-ellipse-content > div.help-images > div.help-image {
    width: 100%;
    height: 200%;
    align-items: flex-start;
    justify-content: center;
  }

  #heading-actions {
    display: none;
  }
}