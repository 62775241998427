.wizard {
    height: 100%;
    width: 100%;
    background-color: #F7F7FA;
    overflow-x: auto;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    padding-top: 70px; 
    padding-bottom: 70px; 
}

.wizard .steps {
    display: flex;
    width: 400px;
    position: relative;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.wizard .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 400px);
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.wizard .steps .step-container {
    text-align: left;
    width: 100%;
    padding-left: 95px;
    padding-bottom: 40px;
}

.wizard .steps .step-container .indicator {
    font-size: 20px;
    width: 30px;
    height: 30px;
    background-color: #506290;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.wizard .steps .step-container .heading {
    font-size: 24px;
    margin-left: 15px;
    color: #506290
}

.wizard .steps .step-container .items {
    margin-left: 45px;
    width: 100%;
}

.wizard .steps .step-container .items .item {
    font-size: 20px;
    color: #506290;
    margin-top: 20px;
}


/* Media Queries */
@media(max-width: 700px) {
    .wizard {
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    .wizard .steps {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 25px 0px;
    }

    .wizard .steps .step-container {
        padding: 0;
        text-align: center;
    }

    .wizard .content{
        flex-direction: column;
        width: 100%;
    }

    .wizard .steps .step-container .heading,
    .wizard .steps .step-container .items {
        display: none;
    }

}