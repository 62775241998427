.home{
  width: 100%;
  position: relative;
  background-color: #fff
}

.home .body{
  width: 100%;
  min-height: calc(100vh - 221px);
  position: relative;    
  flex-direction: column;
  overflow-x: hidden;
}

.home .body .background-content {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 110px;
  background-color: rgba(255,255,255,0.4);
}

.home .body .background-content > p.text-bold {
  font-size: 40px;
  color: #45547A;
}

.home .body .background-content > div > p.text {
  font-size: 16px;
  color: #45547A;
  margin: 5px 0px;
}

.home .body .home-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 335px;
  max-width: 900px;
}

.home .body .home-content .home-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home .body .home-content .home-item .svg-container {
  width: 100px;
  height: 100px;
}

.home .body .home-content .home-item > .heading{
  margin: 20px 0px;
}

.home .body .home-content .home-item > .heading,
.home .body .home-content .home-item > .text {
  font-size: 16px;
  color: #45547A;
  text-align: center;
}

.home .body .signup-card {
  position: absolute;
  top: 100px;
  right: 100px;
  height: 500px;
  width: 358px;
  background-color: #F8F7F7;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.home .body .signup-card > .heading {
  font-size: 26px;
  color: #454F63;
}

.home .body .signup-card > .terms {
  text-align: center;
  opacity: 0.56;
  color: #4A5367;
}


.home .body .signup-card > .terms > span > a { 
  color: #4A5367;
}

.home .body .signup-card > .button {
  width: 100%;
}

.home .error-card {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.16);
  padding: 30px;
  color: #4A5367;
}

.home .error-card > Button {
  margin-top: 30px;
  width: 100%;
}


/* Media Queries */

@media(max-width: 700px){

  .home .body .background-content{
    justify-content: center;
    align-items: center;
    padding: 75px;
  }

  .home .body .background-content > p.text-bold,
  .home .body .background-content > div > p.text{
    text-align: center;
  }

  .home .body .signup-card{
    position: relative;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 500px;
    width: 100%;
    border-radius: 0px;
  }

  .home .body .home-content {
    margin: 20px 0px;
    flex-direction: column;
    max-width: 100%;
  }

  .home .body .home-content .home-item {
    margin: 25px 0px;
  }
}