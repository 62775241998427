.delivery-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vh;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.16);
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    padding-top: 35px;
}

.delivery-footer .line {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.delivery-footer  .duration {
    color: #45547A;
    font-size: 20px;
    margin-left: 5px;
}

.delivery-footer  .routeImage {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delivery-footer  .profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.delivery-footer  .profileActions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.delivery-footer  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 10px;
}

.delivery-footer  .profilePicture {
    width: 55px;
    height: 55px;
    border-radius: 12px;
}

.delivery-footer  .profileButton {
    background-color: #78849E;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
    outline: none;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.16);
    cursor: pointer;
}

@media (min-height: 737px){    
    .delivery-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}