.privacy-container {
  margin: auto;
  width: 100%;
  max-width: 800px;
  padding: 25px;
}

.privacy-container > h1, h2 {
  color: #506290;
}

.privacy-container > p {
  line-height: 20px;
  margin-bottom: 10px;
}