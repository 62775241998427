.ellipse-container {
  width: 100%;
  background-color: #fff;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.ellipse-container .ellipse-content {
  position: absolute;
  top: 0;
  left: -25%;
  width: 150%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ellipse-container .ellipse-content.top {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.ellipse-container .ellipse-content.bottom {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.ellipse-container .ellipse-content .ellipse-children {
  position: absolute;
  width: calc(100% / 1.5);
  left: 16.67%;
  height: 100%;
}

@media(max-width: 700px) {
  .ellipse-container .ellipse-content {
    left: -50%;
    width: 200%;
  }

  .ellipse-container .ellipse-content .ellipse-children {
    width: calc(100% / 2);
    left: 25%;
  }
}