html{
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

div, input{
  box-sizing: border-box
}

p {
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.text-bold {
  font-family: 'Roboto Bold', sans-serif;
}
