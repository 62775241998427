.deliveryContainer {    
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2
}

.deliveryContainer .delivery-loader {
    background-color: rgba(0,0,0,0.8);
    height: 100vh;
    max-width: 417px;
    width: 100%;
    min-width: 320px;
    position: fixed;
    z-index: 1;
    border-radius: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    color: white;
}

.deliveryContainer .delivery {
    min-width: 320px;
    min-height: 100vh;

    max-width: 417px;
    max-height: 736px;

    width: 100%;
    height: 100%;
    display: inline-block;
    margin: auto;
    background-color: #fff;
    position: relative;    
}

.deliveryContainer .modal-card {
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
}

.deliveryContainer .boxelSvg {
    width: 100px;
    height: 120px;
    margin-top: 15px;
}

.deliveryContainer .heading {
    font-size: 90px;
    color: #45547A;
}

.deliveryContainer .boxelText {
    font-size: 18px;
    color: #45547A;
}

.deliveryContainer .smallHr {
    width: 40px;
    height: 3px;
    background-color: #45547A;
    margin: auto;
    margin-bottom: 15px;
}

.deliveryContainer .boxelOpaqueText {    
    color: #959DAD;
    font-size: 16px;
}

.deliveryContainer .actions{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
    margin-bottom: 15px;
}

.deliveryContainer .button {
    background-color: #959DAD;
    width: 136px;
    height: 55px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
    outline: none;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.16);
    cursor: pointer;
    color: #fff;
    font-size: 13px;
}

.deliveryContainer .button-rating {
    background-color: #20D89B;
    width: 100%;
    height: 55px;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.16);
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    box-sizing: border-box;
    margin-top: 20px;
}

.deliveryContainer hr {
    margin-top: 15px;
    margin-bottom: 15px;
    opacity: 0.35;
    margin-left: 20px;
    margin-right: 20px;
}

.deliveryContainer .picture {
    width: 100px;
    height: 100px;
    background-size: cover;
    border-radius: 12px;
}

.deliveryContainer .profileNameText {
    margin-top: 15px;
    font-size: 16px;
    color: #45547A;
}

.deliveryContainer .profileLicenseText {
    font-size: 12px;
    font-weight: 200;
    color: #45547A;
    opacity: 0.6;
    margin-top: 5px;
}

.deliveryContainer .packageDeliveredText {
    color: #45547A;
    font-size: 24px;
    margin-top: 35px;
}

/* Media Queries */

@media (min-height: 737px){    
    .deliveryContainer {    
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .deliveryContainer .delivery{
        box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
        border-radius: 15px;
    }
}