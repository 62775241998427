.input-container {
    min-height: 60px;
    /* min-width: 145px; */
    width: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 12px 17px;
    position: relative;
    border: 1px solid #F5F5F5;
    text-align: left;
    outline: none;
}

.input-container.card {
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.input-container.disabled {
    background-color: #fff;
    border: 2px solid #CCD1DB;
}

.input-container .heading{
  font-size: 14px;
  color: #757C89;
  margin-bottom: 5px;
}

.input-container .heading.disabled{
    color: #CCD1DB;
}

.input-container .input {
    border: none;
    background-color: transparent;
    width: 100%;
    outline: none;
    font-size: 16px;
    color: #4A5367;
}

.input-container .input.disabled {
    color: #CCD1DB;
}

.input-container .input::placeholder {
    font-size: 16px;
    color: #CCD1DB;
    padding: 0;
}

.input-container .dropdown-icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 15px;
    top: 0;
    height: 60px;
    width: 20px; 
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
}

.input-container .address-icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: 0;
    height: 100%;
    width: 60px; 
    cursor: pointer;
    background-color: #506290;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 0;
}

.input-container .dropdown {
    position: relative;
    width: 100%;
}

.input-container .dropdown .dropdown-select {
    color: #CCD1DB;
    font-size: 16px;
    margin-bottom: 10px;
}

.input-container .dropdown .dropdown-item {
    width: 100%;
    padding: 10px 0px;
    color: #4A5367;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(117, 124, 137, 0.5);
    text-align: left;

}
.input-container .dropdown .dropdown-item:last-child {
    border-bottom: none;
}

.input-container .dropdown .dropdown-item:hover {
    color: #CCD1DB;
}

.input-error{
    font-size: 16px;
    color: red;
    margin-top: 5px;
    margin-left: 1px;
}

.date-container > div {
    height: 0px;
}

.hidden-date{
    display: none;
    height: 0;
}

.suggestion-item {
    border-bottom: 1px solid lightgrey;
    padding: 8px;
    word-wrap: break-word;
    overflow: hidden;
}

.suggestion-item:first-of-type {
    margin-top: 15px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.suggestion-item:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.suggestion-item--active {
    background-color: rgb(32, 216, 155) !important;
    border-bottom: 1px solid lightgrey;
    padding: 8px;
    word-wrap: break-word;
    overflow: hidden;
    border-radius: 5px;
    color: white;
}

.suggestion-item--active:first-of-type {
    margin-top: 15px !important;
}
