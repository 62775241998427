.dropzone-container {
  width: 100%;  
  position: relative;
}

.dropzone-container .heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dropzone-container .heading-container .heading {
  font-size: 14px;
  color: #757C89;
}

.dropzone-container .dropzone {
  width: 100%;
  height: 175px;
  border-radius: 20px;
  border: 3px #CCD1DB dotted;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex-direction: column;
}

.dropzone-container .dropzone.active {
  border: 3px #20D89B dotted;
}

.dropzone-container .dropzone.hover {
  border: 3px #20D89B dotted;
}

.dropzone-container .dropzone.error {
  border: 3px red dotted;
}

.dropzone-container .dropzone .text-bold {
  font-size: 16px;
  text-align: center;
  color: #CCD1DB;
}

.dropzone-container .dropzone .text-bold.active{
  color: #20D89B;
}

.dropzone-container .dropzone .svg-icon-container {
  width: 70px;
  margin-bottom: 15px;
}

.dropzone-container .dropzone.active .svg-icon-container > svg * {
  stroke: #20D89B;
}