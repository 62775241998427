.delivery-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    box-shadow: 0px 3px 13px rgba(0,0,0,0.16);
    background-color: #fff;
    text-align: left;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.delivery-header > p {
    margin: 5px;
}

.delivery-header .boxel-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 220px;
    min-height: 110px;
    
    max-width:  241px;
    min-height: 121px;

    width: 58%;
    height: 16.5%;
}

.delivery-header  .header-linear-gradient{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.33), transparent);
}

@media (min-height: 737px){    
    .delivery-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .delivery-header  .header-linear-gradient{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}