.container {
    width: 100%;
    height: 360px;

    box-shadow: 0px -1px 7px rgba(0,0,0,0.16);
    display: flex;
    align-items: center;
    padding: 0px 100px;
}

.container .carousel{

}