/**
 * @file: src/components/otp/otp.style.js
 * @description Link Component Style
 * @copyright: Verge Technologies 2018
 * @author Fabio Loreggian fabio@verge.co.za
*/

 .otpContainer {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
 }

 .otpContainer .box-container {
   flex: 1;
   align-items: center;
   justify-content: center;
   display: flex;
 }

 .otpContainer .box-container .box {
  color: #506290;
  font-size: 34px;
  border: 2px solid #CBCBCB;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 47px;
  height: 52px;
  outline: none;
  margin: 0px 15px;
  /* font-family: FONT_FAMILY_REGULAR; */
 }

 .otpContainer .box-container .box {
   border-color: #20D89B;
 }

