.web-footer {
  width: 100%;
}

.web-footer .footer-img {
  background-image: url('../../../assets/images/footerBg.png');
  background-color: #20D89B;
  height: 438px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
}

.web-footer .footer-img .heading {
  color: #fff;
  font-size: 28px;
  text-align: center;
}

.web-footer .footer-img .stores {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.web-footer .footer-img .stores > a > img {
  cursor: pointer;
}

.web-footer .footer-img .stores > a > img:first-child {
  margin-right: 10px;
}

.web-footer .footer-img .stores > a > img:last-child {
  margin-left: 10px;
}

.web-footer .footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-footer .footer-contact > p {
  color: #fff;
  font-size: 18px;
  margin: 5px;
}

.web-footer .footer-contact > .footer-input {
  position: relative;
  margin-top: 15px;
}

.web-footer .footer-contact > .footer-input > input {
  padding: 15px;
  border: none;
  border-radius: 8px;
  width: 350px;
  font-size: 20px;
  outline: none;
  height: 60px;

}

.web-footer .footer-contact > .footer-input > input::placeholder {
  color: #CCD1DB;
}

.web-footer .footer-contact > .footer-input > button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #506290;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  cursor: pointer;
}


@media(max-width: 700px){
  .web-footer .footer-img {
    height: 376px;
  }  

  .web-footer .footer-contact {
    display: none;
  }
}